.c-section01{
  margin: 0 auto 50px;
}

.c-section02{
  margin: 0 auto 50px;
  padding: 30px 40px;
  box-shadow: 0px 0px 16px -6px rgba(0,0,0,.2);
  background: #fff;
}

.c-section03{
  margin: 0 auto 50px;
  padding: 80px 20px;
  box-shadow: 0px 0px 16px -6px rgba(0,0,0,.2);
  background: #fff;
}

.c-section04{
  margin: 0;
  position: relative;
  &::before{
    @include createContent(0, -20px, 0, -20px);
  }
  &--white{
    &::before{
      background: #fff;
    }
  }
  &--gray{
    &::before{
      background: #f6f6f6;
    }
  }

}


.c-section__inner{
  width: 1240px;
  padding: 100px 0;
  position: relative;
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .c-section03{
    padding: 40px 20px;
  }

  .c-section__inner{
    width: 100%;
    padding: 60px 20px;
  }
}


.c-section__mb01{
  margin: 0 auto 10px;
}
.c-section__mb02{
  margin: 0 auto 20px;
}
.c-section__mb03{
  margin: 0 auto 30px;
}
.c-section__mb04{
  margin: 0 auto 40px;
}
.c-section__mb05{
  margin: 0 auto 50px;
}
.c-section__mb06{
  margin: 0 auto 60px;
}
.c-section__mb07{
  margin: 0 auto 70px;
}
.c-section__mb08{
  margin: 0 auto 80px;
}
.c-section__mb09{
  margin: 0 auto 90px;
}
.c-section__mb10{
  margin: 0 auto 100px;
}
.c-section__mb11{
  margin: 0 auto 110px;
}
.c-section__mb12{
  margin: 0 auto 120px;
}
.c-section__mb13{
  margin: 0 auto 130px;
}
.c-section__mb14{
  margin: 0 auto 140px;
}
.c-section__mb15{
  margin: 0 auto 150px;
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  .c-section02{
    padding: 30px;
  }

  .c-section__inner{
    padding: 30px 20px 30px;
  }
}