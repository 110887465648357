@font-face {
  font-family: "noto_regular";
  src: url("/fonts/NotoSansCJKjp-Regular.woff2") format('woff2'),
        url("/fonts/NotoSansCJKjp-Regular.otf")  format('opentype');
}

@font-face {
  font-family: "noto_black";
  src: url("/fonts/NotoSansCJKjp-Black.woff2") format('woff2'),
        url("/fonts/NotoSansCJKjp-Black.otf")  format('opentype');
}

@font-face {
  font-family: "noto_bold";
  src: url("/fonts/NotoSansCJKjp-Bold.woff2") format('woff2'),
        url("/fonts/NotoSansCJKjp-Bold.otf")  format('opentype');
}