// text位置
.u-text__left{
  text-align: left!important;
}
.u-text__center{
  text-align: center!important;
}
.u-text__right{
  text-align: right!important;
}

// text color
.u-color--red01{
  color: #e00000;
}

// margin-bottom
.u-margin__bottom00{
  margin-bottom: 0px!important;
}
.u-margin__bottom10{
  margin-bottom: 10px!important;
}
.u-margin__bottom20{
  margin-bottom: 20px!important;
}
.u-margin__bottom30{
  margin-bottom: 30px!important;
}
.u-margin__bottom40{
  margin-bottom: 40px!important;
}
.u-margin__bottom50{
  margin-bottom: 50px!important;
}
.u-margin__bottom60{
  margin-bottom: 60px!important;
}
.u-margin__bottom70{
  margin-bottom: 70px!important;
}
.u-margin__bottom80{
  margin-bottom: 80px!important;
}
.u-margin__bottom90{
  margin-bottom: 90px!important;
}
.u-margin__bottom100{
  margin-bottom: 100px!important;
}

//display
@media screen and (min-width:$rwdBreakPoint02){
  .u-display__pcdib{
    display: inline-block!important;
  }
  .u-display__spdib{
    display: none!important;
  }

  .u-display__pcdb{
    display: block!important;
  }
  .u-display__spdb{
    display: none!important;
  }
}

@media screen and (max-width:$rwdBreakPoint01){
  .u-display__pcdib{
    display: none!important;
  }
  .u-display__spdib{
    display: inline-block!important;
  }
  .u-display__pcdb{
    display: none!important;
  }
  .u-display__spdb{
    display: block!important;
  }
}