//pager common
.c-pager{
  margin: 50px auto 0;
  display: flex;
  justify-content: center;
  li{
    margin: 0 10px;
    a, span.is-active{
      min-width: 30px;
      height: 30px;
      padding: 5px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a{
      background: #fff;
      text-decoration: none;
    }
    span.is-active{
      background: $baseColor;
      color: #fff;
    }
  }
}
.c-pager__arrow--prev, .c-pager__arrow--next{
  a{
    border: 1px solid $baseColor;
    position: relative;
    span{
      text-indent: -99999em;
    }
    &::before, &::after{
      content: '';
      margin-left: -3px;
      width: 7px;
      height: 1px;
      background: $baseColor;
      display: inline-block;
      position: absolute;
      top: 50%;
    }
    &::before{
      margin-top: -3.5px;
      transform: rotate(-45deg);
    }
    &::after{
      margin-top: 1.5px;
      transform: rotate(45deg);
    }
  }
  .is-disable{
    border: 1px solid #ccc;
    pointer-events: none;
    &::before, &::after{
      background: #ccc;
    }
  }
}
.c-pager__arrow--next{
  transform: rotate(180deg);
}

// window size more than 768px
// $rwdBreakPoint02 = 768
@media screen and (min-width:$rwdBreakPoint01){
  .c-pager{
    li{
      a{
        transition: all .2s ease-in;
        &:hover{
          background: $baseColor;
          color: #fff;
        }
      }
    }
  }
}
.c-pager__arrow--prev, .c-pager__arrow--next{
  a{
    &:hover{
      &::before, &::after{
        background: #fff;
      }
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  //pager common
  .c-pager{
    li{
      margin: 0 2.5px;
      a, span.is-active{
        min-width: 40px;
        height: 40px;
      }
    }
    .c-pager__arrow--prev{
      margin: 0 15px 0 0;
    }
    .c-pager__arrow--next{
      margin: 0 0 0 15px;
    }
  }
}
// window size less than 375px
// $rwdBreakPoint05 = 374.9
@media screen and (max-width:$rwdBreakPoint05){
  //pager common
  .c-pager{
    li{
      a, span.is-active{
        min-width: 30px;
        height: 30px;
      }
    }
    .c-pager__arrow--prev{
      margin: 0 10px 0 0;
    }
    .c-pager__arrow--next{
      margin: 0 0 0 10px;
    }
  }
}