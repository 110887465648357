@charset "utf-8";

*{
  box-sizing: border-box;
}
html{
  position: relative;
  overflow: auto;
}
body{
  width: 100%;
  min-width: $minContentWidth;
  background-color: #fff;
  color: $baseFontColor;
  font-size: $baseFontSize01;
  font-family: $baseFontFamily;
  line-height: 1.75;
  font-family: "noto_regular";
  word-wrap: break-word;
  position: relative;
  counter-reset: number01 number02;
  transition: background-color .5s linear;
/*   &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 99999;
  }
  &.loaded{
    position: static;
    &::before{
      content: none;
    }
  } */
  &.is-hamburger--open{
    background-color: $baseColor;
    #document{
      overflow: hidden;
    }
  }
}

a{
  color: $baseLinkColor;
}

#document{
  overflow: hidden;
}

@media screen and (max-width:$rwdBreakPoint01){
  body{
    min-width: 100%;
  }
  img{
    width: 100%;
  }
}

// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  a{
    @include hovertext($hoverStyle: none);
  }
  a{
    img{
      transition:opacity .2s ease-in;
      -webkit-transition:opacity .2s ease-in;
      -moz-transition:opacity .2s ease-in;
      filter:alpha(opacity=100)!important;
      -moz-opacity:1.0!important;
      opacity:1.0!important;
    }
    &:hover{
      img{
        filter:alpha(opacity=70)!important;
        -moz-opacity:.7!important;
        opacity:.7!important;
      }
    }

  }
}