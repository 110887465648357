// /ランキングページ固有

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .p-ranking{
    .c-section__aside__ranking{
      display: none;
    }
  }
}