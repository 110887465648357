
// リード
.c-read01{
  margin: 0 0 40px;
  text-align: center;
  .c-heading05 + & {
    margin-top: -20px;
  }
  .c-heading08 + & {
    margin-top: -40px;
  }
}
.c-read02{
  margin: 0 0 40px;
  text-align: center;
  font-family: 'noto_bold';
  font-size: 20px;
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  .c-read02{
    margin: 0 0 40px;
    text-align: center;
    font-family: 'noto_bold';
    font-size: 18px;
  }
}