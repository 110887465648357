// ページタイトル
.c-heading01{
  margin: 0 0 30px;
  border-bottom: 1px solid #ccc;
  padding: 0 0 15px;
  font-family: "noto_bold";
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.c-heading01__number{
  font-family: "note_regular";
  font-size: 14px;
  vertical-align: middle;
}

// サイドナビのランキング見出し
.c-heading01__ranking{
  &::before{
    content: '';
    margin: 0 10px 0 0;
    width: 22px;
    height: 15px;
    background: url(/images/common/ico-rank01.svg) no-repeat 0 0;
    display: inline-block;
  }
}

.c-heading02{
  margin: 0 0 20px;
  font-family: "noto_bold";
  font-size: 26px;
}

.c-heading03{
  margin: 0 0 15px;
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px;
  font-family: "noto_bold";
  font-size: 16px;
}
.c-heading04{
  margin: 0;
  font-family: "noto_bold";
  font-size: 15px;
  span{
    font-family: "note_regular";
    font-weight: normal;
  }
}
.c-heading05{
  margin: 0 0 20px;
  font-family: "noto_bold";
  font-size: 20px;
  text-align: center;
}

.c-heading06{
  margin: 0 0 40px;
  color: $baseColor;
  font-family: "noto_bold";
  font-size: 22px;
}

// side nav 
.c-heading07{
  margin: 0;
  padding: 10px 20px;
  background: $baseColor;
  color: #fff;
  font-family: "noto_bold";
  font-size: 18px;
}

.c-heading08{
  margin: 0 0 40px;
  color: $baseColor;
  font-family: "noto_bold";
  font-size: 30px;
  text-align: center;
  span{
    margin: 0 auto;
    border-radius: 20px;
    min-width: 100px;
    padding: 5px;
    background: $baseColor;
    color: #fff;
    font-size: 13px;
    display: inline-block;
  }
}

.c-heading09{
  margin: 0;
  font-family: "noto_bold";
  font-size: 20px;
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .c-heading01{
    margin: 0 -20px 20px;
    padding: 0 20px 15px;
  }

  .c-heading02{
    font-size: 20px;
  }

  .c-heading08{
    font-size: 24px;
  }

  .c-heading09{
    font-size: 18px;
  }
  
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  .c-heading08{
    font-size: 20px;
  }
  .c-heading09{
    font-size: 16px;
  }
  
}

