// /Summaryとはページ固有
.p-about__index{
  .c-button02{
    margin: 0;
    width: 360px;
  }
  .p-kv{
    padding: 160px 0;
    background: #f6f6f6;
    position: relative;
    &::before{
      content: '';
      margin-left: -150px;
      width: 974px;
      height: 550px;
      background: url(/images/about/img-kv.jpg) no-repeat 0 0;
      display: block;
      position: absolute;
      left: 50%;
      top: 90px;
    }
  }
  .p-kv__inner{
    margin: auto;
    width: 1280px;
    padding: 0 20px;
  }
  .p-kv__block{
    p{
      color: $baseColor;
      font-family: 'noto_bold';
      line-height: 1.5;
    }
  }
  .p-kv__text--main{
    margin: 0 0 20px;
    font-size: 13px;
    span{
      margin: 10px 0 0 0;
      display: inline-block;
      span{
        border-bottom: 4px solid $baseColor;
        font-size: 50px;
        display: inline;
      }
    }
  }
  .p-kv__text--lead{
    font-size: 24px;
  }

  #p-section__sample{
    .p-sample__movie{
      margin: 0 auto;
      width: 1030px;
    }
    p{
      margin: 5px 0 0 0;
      font-family: 'noto_bold';
      font-size: 18px;
    }
  }

  #p-section__price{
    .c-button02{
      margin: 50px auto 0;
    }
  }

  #p-section__outro{
    .c-button02{
      margin: 30px auto 0;
    }
  }

}

// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  // /Summaryとはページ固有
  .p-about__index{
    .p-kv{
      min-height: 700px;
    }
    .l-main__inner{
      padding-bottom: 0;
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  // /Summaryとはページ固有
  .p-about__index{
    .p-kv{
      padding: 30px 20px;
      position: relative;
      &::before{
        content: none;
      }
    }
    .p-kv__inner{
      margin: auto;
      width: 100%;
      padding: 0;
    }
    .p-kv__block{
      text-align: center;
      .c-button02{
        margin: 0 auto;
      }
    }
    .p-kv__text--main{
      margin: 0 0 20px;
      span{
        span{
          font-size: 36px;
        }
      }
    }
    .p-kv__img{
      margin: 20px 0 0 0;
    }
    .p-kv__text--lead{
      margin: 20px 0;
      font-size: 20px;
    }
    .c-button02{
      width: 255px;
    }
    .l-main__inner{
      padding: 0;
    }

    #p-section__intro{
      .c-layout01__lead{
        span{
          margin: 0 0 0 -190px;
          width: 110px;
          top: -28px;
        }
      }
    }
    #p-section__sample{
      .p-sample__movie{
        width: 100%;
      }
    }
    #p-section__price{
      .c-button02{
        margin: 30px auto 0;
      }
    }

    #p-section__outro{
      .c-layout01__lead{
        span{
          margin: 0 0 0 -190px;
          width: 126px;
          top: -28px;
        }
      }
    }
  }
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  // /Summaryとはページ固有
  .p-about__index{
    .p-kv__text--main{
      margin: 0 0 20px;
      font-size: 12px;
      span{
        span{
          font-size: 28px;
        }
      }
    }
    .p-kv__text--lead{
      margin: 20px 0;
      font-size: 14px;
    }

    #p-section__intro{
      .c-layout01__lead{
        span{
          margin: 0 0 0 -180px;
        }
      }
    }

    #p-section__sample{
      .p-sample__movie{
        p{
          font-size: 15px;
        }
      }
    }
    #p-section__price{
      .c-button02{
        margin: 20px auto 0;
      }
    }

    #p-section__outro{
      .c-layout01__lead{
        span{
          margin: 0 0 0 -150px;
        }
      }
    }
  }
}