@charset "utf-8";
/*====================================================================================================
//////////////////////////////////////////////////////////////////////////////////////////////////////

    version: 1.0
    created: 2022/05/05
    update : -

//////////////////////////////////////////////////////////////////////////////////////////////////////
====================================================================================================*/
// foundation
@import "foundation/_function";
@import "foundation/_mixin";
@import "foundation/_vals";
@import "foundation/_ress";
@import "foundation/_font";
@import "foundation/_base";

// layout
@import "layout/_header";
@import "layout/_layout";
@import "layout/_footer";
@import "layout/_aside";

// object/component/lib
@import "object/component/lib/slick";
@import "object/component/lib/slick-theme";
@import "object/component/lib/modaal";

// object/component
@import "object/component/_section";
@import "object/component/_heading";
@import "object/component/_card";
@import "object/component/_list";
@import "object/component/_table";
@import "object/component/_button";
@import "object/component/_form";
@import "object/component/_pager";
@import "object/component/_module";
@import "object/component/_slide";
@import "object/component/_accordion";
@import "object/component/_aside";
@import "object/component/_readtext";
@import "object/component/_modal";

// object/component/project ページ固有のスタイルを定義
// 動画講座詳細ページ固有のスタイル定義
@import "object/project/_detail";
// ランキングページ固有のスタイル定義
@import "object/project/_ranking";
// Summaryとはページ固有のスタイル定義
@import "object/project/_about";

// utility
@import "utility/_utility";