//accordion common


// window size more than 768px
// $rwdBreakPoint02 = 768
@media screen and (min-width:$rwdBreakPoint01){
  .js-accordion__pc{
    .js-accordion__content{
      width: 0;
      height: 0;
      line-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity .5s ease-out;
    }
    &.is-active{
      .js-accordion__content{
        width: 100%;
        height: auto;
        line-height: normal;
        opacity: 1;
      }
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .js-accordion__sp{
    .js-accordion__content{
      width: 0;
      height: 0;
      line-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity .5s ease-in;
    }
    &.is-active{
      .js-accordion__content{
        width: 100%;
        height: auto;
        line-height: normal;
        opacity: 1;
      }
    }
  }
}