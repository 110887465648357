// /動画詳細ページ固有

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .p-detail .l-main__inner{
    padding: 0 20px 30px;
    .c-section02{
      margin: 0 -20px 50px;
    }
  }
}