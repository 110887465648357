
// バナーリスト
// おすすめコンテンツなど
.c-list__banner01{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li{
    margin: 0 0 20px;
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

//お知らせリスト
.c-list__news{
  li{
    display: table;
    .c-list__news__update{
      width: 9em;
      display: table-cell;
    }
    .c-list__news__title{
      width: calc(100% - 9em);
      display: table-cell;
      &.__new{
        &::after{
          content: 'NEW';
          margin: 0 0 0 10px;
          padding: 1px 7px;
          background: #a40000;
          color: #fff;
          font-size: 11px;
          text-decoration: none;
          display: inline-block;
        }
      }
    }
    a.c-list__news__title{
      @include hovertext(none);
      cursor: pointer;
    }
  }
}


// 注釈リスト
.c-list__notes{
  li{
    margin: 0 0 0 1em;
    font-family: 'noto_regular';
    font-weight: normal;
    text-indent: -1em;
    text-align: left;
    &::before{
      content: '※';
    }
  }
}

// 黒丸リスト
.c-list__disc{
  list-style-type: disc;
  margin: 0 0 0 1em;
  li{
    margin: 0 0 0 0;
  }
}

.c-list__plan{
  display: flex;
  justify-content: space-between;
  li{
    margin: 0;
    flex-basis: calc((100% - 50px) / 2);
    max-width: calc((100% - 50px) / 2);
    padding: 100px 10px;
    background: #e8eef7;
    color: $baseColor;
    text-align: center;
    position: relative;
    &.c-list__plan--recommend{
      &::before{
        content: '';
        border: 60px solid #ffe21f;
        border-right-color: transparent;
        border-bottom-color: transparent; 
        height: 0;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
      }
      &::after{
        content: 'おすすめ！';
        font-family: 'noto_bold';
        font-weight: bold;
        transform: rotate(-45deg);
        position: absolute;
        top: 28px;
        left: 7px;
        z-index: 1000;
      }
    }

    &.c-list__plan__item{
      line-height: 1.5;
      span{
        font-family: 'noto_bold';
        font-size: 20px;
        position: relative;
        z-index: 10;
        span{
          margin-top: 10px;
          font-size: 56px;
          display: inline-block;
        }
      }
      .c-list__plan__note{
        margin: 0;
        font-family: 'noto_regular';
        font-size: 18px;
        position: relative;
        z-index: 10;
      }
    }
  }
}

.c-list__order01{
  margin: 0 0 1em 2.5em;
  padding: 0;
  li{
    list-style: none;
    counter-increment: cnt;
    &::before{
      content: "(" counter(cnt) ") ";
      margin-left: -2.5em;
      width: 2.5em;
      display:inline-block;
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .c-list__plan{
    li{
      flex-basis: calc((100% - 15px) / 2);
      max-width: calc((100% - 15px) / 2);
      padding: 50px 10px;
      &.c-list__plan--recommend{
        &::before{
          content: '';
          border: 50px solid #ffe21f;
          border-right-color: transparent;
          border-bottom-color: transparent; 
        }
        &::after{
          font-size: 12px;
          top: 22px;
          left: 4px;
        }
      }
      &.c-list__plan__item{
        span{
          font-size: 15px;
          span{
            margin-top: 5px;
            font-size: 34px;
          }
        }
        .c-list__plan__note{
          font-size: 14px;
        }
      }
    }
  }
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  //お知らせリスト
  .c-list__news{
    li{
      margin: 0 0 30px;
      display: block;
      .c-list__news__update{
        margin: 0 0 5px;
        width: 100%;
        font-family: 'noto_bold';
        font-size: 13px;
        display: block;
      }
      .c-list__news__title{
        width: 100%;
        display: block;
      }
      a.c-list__news__title{
        @include hovertext(none);
        cursor: pointer;
      }
    }
  }

  //Plan選択のradio
  .c-list__plan{
    li{
      flex-basis: calc((100% - 10px) / 2);
      max-width: calc((100% - 10px) / 2);
      padding: 40px 5px 30px;
      &.c-list__plan--recommend{
        &::before{
          content: '';
          border: 35px solid #ffe21f;
          border-right-color: transparent;
          border-bottom-color: transparent; 
        }
        &::after{
          font-size: 10px;
          top: 15px;
          left: -2px;
        }
      }
      &.c-list__plan__item{
        span{
          span{
            margin-top: 5px;
            font-size: 22px;
          }
        }
        .c-list__plan__note{
          font-size: 11px;
        }
      }
    }
  }
}