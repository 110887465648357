
// index module
.c-card01{
  margin: -20px 0 0 -20px;
  display: flex;
  flex-wrap: wrap;
  a{
    margin: 20px 0 0 20px;
    padding: 0 20px 20px;
    box-shadow: 0px 0px 16px -6px rgba(0,0,0,.2);
    background: #fff;
    color: #333;
    text-decoration: none;
    flex-basis: 260px;
    max-width: 260px;
    display: block;
  }
  p{
    font-size: 14px;
  }
  .c-card01__item__thumb{
    margin: 0 -20px 20px;
  }
  .c-card01__item__title{
    margin: 0 0 10px;
    font-family: "noto_bold";
    font-size: 16px;
  }
  .c-card01__item__author{
    margin: 0 0 10px;
    color: $baseColor;
    font-size: 12px;
  }
  .c-card01__item__meta{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  .c-card01__item__update{
    color: #9c9c9c;
  }
  // 横幅が40px狭いボックス内ではカードのマージンを調整
  .c-section03 & {
    margin: -15px 0 0 -15px;
    a{
      margin: 15px 0 0 15px;
      padding: 0 20px 20px;
      flex-basis: 250px;
      max-width: 250px;
    }
  }
}

// ranking pages index module
.c-card01__ranking{
  margin: -40px 0 0 -20px;
  a{
    margin: 60px 0 0 20px;
    position: relative;
    &::before{
      content: "0" counter(number02);
      counter-increment: number02 1;
      padding: 0 0 0 35px;
      background: url(/images/common/ico-rank04.svg) no-repeat 0 50%;
      background-size: 30px 20px;
      font-size: 20px;
      position: absolute;
      left: 0;
      top: -40px;
    }
    &:nth-child(1){
      &::before{
        background: url(/images/common/ico-rank01.svg) no-repeat 0 50%;
        background-size: 30px 20px;
        color: #cda815;
      }
    }
    &:nth-child(2){
      &::before{
        background: url(/images/common/ico-rank02.svg) no-repeat 0 50%;
        background-size: 30px 20px;
        color: #bcbcbc;
      }
    }
    &:nth-child(3){
      &::before{
        background: url(/images/common/ico-rank03.svg) no-repeat 0 50%;
        background-size: 30px 20px;
        color: #ad824b;
      }
    }
    &:nth-child(n+10){
      &::before{
        content: counter(number02);
      }
    }
  }
}

//about pages feature
.c-card02{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__item{
    box-shadow: 0px 0px 16px -6px rgba(0,0,0,.2);
    flex-basis: 380px;
    background: #fff;
    font-family: 'noto_bold';
    text-align: center;
    span{
      min-height: 6em;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.c-card03{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  &__item{
    flex-basis: 380px;
    font-family: 'noto_bold';
    span{
      margin: 10px 0 0 0;
      padding: 0 0 0 1.5em;
      background: url(/images/common/ico-play.png) no-repeat 0 5px;
      background-size: 15px;
      display: block;
    }
  }
}

// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  //about pages feature
  .c-card02{
    margin: -50px 0 0 -50px;
    &__item{
      margin: 50px 0 0 50px;
      max-width: 380px;
    }
  }

  .c-card03{
    margin: -50px 0 0 -50px;
    &__item{
      margin: 50px 0 0 50px;
      max-width: 380px;
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  // index module
  .c-card01{
    a{
      flex-basis: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
    // 横幅が40px狭いボックス内ではカードのマージンを調整
    .c-section03 & {
      a{
        flex-basis: calc(50% - 15px);
        max-width: calc(50% - 15px);
      }
    }
  }

  // ranking pages index module
  .c-card01__ranking{
    margin: -20px 0 0 -20px;
    a{
      margin: 60px 0 0 20px;
    }
  }

  //about pages feature
  .c-card02{
    margin: -20px 0 0 0;
    justify-content: space-around;
    &__item{
      margin: 20px 0 0 0;
      flex-basis: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }

  .c-card03{
    margin: -20px 0 0 0;
    justify-content: space-around;
    &__item{
      margin: 20px 0 0 0;
      flex-basis: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  // index module
  .c-card01{
    margin: 0 0 0 0;
    display: block;
    a{
      margin: 20px 0 0 0;
      max-width: none;
    }
    // 横幅が40px狭いボックス内ではカードのマージンを調整
    .c-section03 & {
      margin: 0 0 0 0;
      display: block;
      a{
        margin: 20px 0 0 0;
        max-width: none;
      }
    }
  }

  // ranking pages index module
  .c-card01__ranking{
    margin: 0 0 0 0;
    a{
      margin: 60px 0 0 0;
    }
  }

    //about pages feature
    .c-card02{
      display: block;
      &__item{
        margin: 20px 0 0 0;
        max-width: none;
      }
    }

    .c-card03{
      display: block;
      &__item{
        margin: 20px 0 0 0;
        max-width: none;
      }
    }
}