// input text
.c-form__text{
  border: 1px solid #ccc;
  width: 100%;
  padding: 18px 20px;
  background-color: #f6f6f6;
  color: $baseFontColor;
  font-size: 16px;
  font-weight: normal;
  @include setTransition(all, .1s, linear);
  &:placeholder-shown{
    border: 1px solid #ccc;
    background-color: #f6f6f6;
  }
  &:not(:placeholder-shown){
    border: 1px solid $baseColor;
    background-color: #fff;
  }
  &.has-error {
    border: 1px solid #e00000;
    box-shadow: 0px 0px 16px -6px rgba(224,0,0,.7);
    background-color: #fff;
  }
  &:invalid{
    border: 1px solid #ccc;
    background-color: #f6f6f6;
  }
  &:focus, &:valid{
    border: 1px solid $baseColor;
    background-color: #fff;
  }
}
::placeholder{
  color: #9c9c9c;
}
input:focus::placeholder{
  color: transparent;
}

.c-form__text__type02{
  margin: 0 4px;
  &:first-child{
    margin-left: 0;
  }
  &:last-child{
    margin-right: 0;
  }
}

.c-form__text__type03{
  margin: 0 4px;
  flex-basis: 100px;
  max-width: 100px;
  &:first-child{
    margin-left: 0;
  }
  &:last-child{
    margin-right: 0;
  }
}

.c-input__wrap{
  display: flex;
  align-items: center;
  .c-form__spacer{
    margin: 0 8px;
    display: inline-block;
  }
}

// input select box
.c-input__select{
  border: 1px solid $baseColor;
  width: 100%;
  padding: 18px 20px;
  background-color: #fff;
  color: $baseFontColor;
  font-size: 16px;
  font-weight: normal;
  text-overflow: ellipsis;
  cursor: pointer;
  @include setTransition(all, .1s, linear);
  &.has-error {
    border: 1px solid #e00000;
    box-shadow: 0px 0px 16px -6px rgba(224,0,0,.7);
    background-color: #fff;
  }
  &:invalid{
    border: 1px solid #ccc;
    background-color: #f6f6f6;
  }
  option{
    border: 1px solid $baseColor;
    background-color: #fff;
    &:first-child{
      border: 1px solid #ccc;
      background-color: #f6f6f6;
    }
  }
}

.c-input__select__wrap{
  position: relative;
  &::before, &::after{
    content: '';
    margin-top: 0px;
    border-top: 1px solid #666;
    width: 8px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: rotate(45deg);
    @include setTransition(margin, .2s, ease-in);
  }
  &::after{
    right: 10px;
    transform: rotate(-45deg);
  }
}

//input チェックボックス
.c-form__check{
  display: none;
  &:checked + label::before{
    background: $baseColor;
  }
  &:checked + label::after{
    content: '';
    margin: 9px 0 0 7px;
    width: 15px;
    height: 11px;
    background: url(/images/common/img-check-white01.png) no-repeat 0 0;
    cursor: pointer;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.c-form__check + label{
  position: relative;
  display: flex;
  &::before{
    content: '';
    border: 1px solid $baseColor;
    margin: 0 15px 0 0;
    width: 30px;
    height: 30px;
    background-color: #fff;
    cursor: pointer;
    display: inline-block;
    @include setTransition(background-color, .2s, ease-in);
  }
}


//input ラベル
.c-form__label{
  margin: 0 0 5px;
  width: 100%;
  display: flex;
  align-items: center;
}

//input 必須
.c-form__require{
  border: 1px solid #a40000;
  margin: 0 0 0 .5em;
  padding: 2px 4px;
  color: #a40000;
  font-size: 12px;
  line-height: 1;
}

// input 下の注釈
.c-form__note{
  margin: .5em 0 0 0;
  li{
    margin: 0;
    padding: 0 0 0 1em;
    font-size: 12px;
    text-indent: -1em;
    &::before{
      content: '※';
    }
  }
}
//input block
.c-input__row{
  margin: 0 0 30px;
}

//
.c-form__text__confirm{
  font-size: 22px;
  font-weight: bold;
}

//エラー表示
.c-form__error{
  margin: 5px 0 0 0;
  color: #e00000;
  font-size: 14px;
}

//Plan選択のradio
.c-form__planlist{
  display: flex;
  justify-content: space-between;
  li{
    flex-basis: calc((100% - 20px) / 2);
    max-width: calc((100% - 20px) / 2);
    padding: 130px 10px 60px;
    text-align: center;
    position: relative;
    &.c-form__planlist--recommend{
      &::before{
        content: '';
        border: 60px solid #ffe21f;
        border-right-color: transparent;
        border-bottom-color: transparent; 
        height: 0;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
      }
      &::after{
        content: 'おすすめ！';
        font-family: 'noto_bold';
        font-weight: bold;
        transform: rotate(-45deg);
        position: absolute;
        top: 28px;
        left: 7px;
        z-index: 1000;
      }
    }

    label{
      line-height: 1.5;
      span{
        font-family: 'noto_bold';
        font-size: 20px;
        cursor: pointer;
        position: relative;
        z-index: 10;
        span{
          margin-top: 10px;
          font-size: 56px;
          display: inline-block;
        }
      }
      &::before{
        content: '';
        background: #f6f6f6;
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }
    .c-form__planlist__note{
      margin: 0;
      font-family: 'noto_regular';
      font-size: 18px;
      position: relative;
      z-index: 10;
    }
  }
}
.c-form__radio--plan{
  display: none;
  &:checked + label{
    color: #001f53;
  }
  & + label::after{
    content: '';
    margin: 0 0 0 -20px;
    width: 40px;
    height: 40px;
    background: url(/images/common/img-check-gray01.png) no-repeat 0 0;
    display: block;
    position: absolute;
    z-index: 100;
    top: 60px;
    left: 50%;
  }
  &:checked + label::after{
    background: url(/images/common/img-check-navy01.png) no-repeat 0 0;
  }
  &:checked + label::before{
    background: #e8eef7;
  }
}

//form 見出し下にあるステップ
.c-step{
  margin: 80px 0 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  li{
    margin: 0 20px;
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 7em;
    min-width: 7em;
    max-width: 7em;
    position: relative;
    &::before{
      content: counter(number01);
      counter-increment: number01 1;
      margin-left: -20px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background: #ccc;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      top: -45px;
      z-index: 100;
    }
    &::after{
      content: '';
      border-top: 1px solid #ccc;
      width: 100%;
      height: 1px;
      position: absolute;
      right: calc(100% - 32px);
      top: -25px;
      z-index: 1;
    }
    &:first-child::after{
      content: none!important;
    }
    &.is-active{
      color: $baseColor;
      &::before{
        background: $baseColor;
      }
      &::after{
        content: '';
        border-top: 1px solid $baseColor;
      }
    }
  }
}

// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  .c-form__planlist{
    li{
      .c-form__radio--plan{
        & + label{
          opacity: 1;
          transition: opacity linear .1s;
        }
      }
      &:hover{
        .c-form__radio--plan{
          & + label{
            opacity: .7;
          }
          &:checked + label{
            opacity: 1;
          }
        }
      }
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  //Plan選択のradio
  .c-form__planlist{
    li{
      flex-basis: calc((100% - 15px) / 2);
      max-width: calc((100% - 15px) / 2);
      padding: 70px 10px 30px;
      &.c-form__planlist--recommend{
        &::before{
          content: '';
          border: 50px solid #ffe21f;
          border-right-color: transparent;
          border-bottom-color: transparent; 
        }
        &::after{
          font-size: 12px;
          top: 22px;
          left: 4px;
        }
      }
      label{
        span{
          font-size: 15px;
          span{
            margin-top: 5px;
            font-size: 34px;
          }
        }
        .c-form__planlist__note{
          font-size: 14px;
        }
        &::after{
          margin: 0 0 0 -15px;
          width: 30px;
          height: 30px;
          background-size: contain;
          top: 20px;
        }
      }
      .c-form__radio--plan:checked + label::after{
        background-size: contain;
      }
    }
  }

    //form 見出し下にあるステップ
    .c-step{
      li{
        margin:0 2px;
        font-size: 13px;
      }
    }
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  // input text
  .c-form__text{
    padding: 18px 15px;
  }

  .c-form__text__type02{
    margin: 0 2.5px;
  }
  
  .c-form__text__type03{
    flex-basis: 100px;
    max-width: 100px;
  }
  
  .c-input__wrap{
    .c-form__spacer{
      margin: 0 3px;
      display: inline-block;
    }
  }
  

  .c-form__check{
    &:checked + label::after{
      content: '';
      margin: 6px 0 0 3px;
      width: 15px;
      height: 11px;
    }
  }

.c-form__check + label{
  font-size: 13px;
  &::before{
    content: '';
    margin: 2px 5px 0 0;
    width: 20px;
    height: 20px;
  }
}

  //Plan選択のradio
  .c-form__planlist{
    li{
      flex-basis: calc((100% - 10px) / 2);
      max-width: calc((100% - 10px) / 2);
      padding: 60px 5px 20px;
      &.c-form__planlist--recommend{
        &::before{
          content: '';
          border: 35px solid #ffe21f;
          border-right-color: transparent;
          border-bottom-color: transparent; 
        }
        &::after{
          font-size: 10px;
          top: 15px;
          left: -2px;
        }
      }
      label{
        span{
          span{
            margin-top: 5px;
            font-size: 22px;
          }
        }
        .c-form__planlist__note{
          font-size: 11px;
        }
        &::after{
          margin: 0 0 0 -12px;
          width: 24px;
          height: 24px;
          top: 20px;
        }
      }
    }
  }

  //form 見出し下にあるステップ
  .c-step{
    li{
      margin:0 2px;
      font-size: 11px;
    }
  }
}