//side nav
#l-sidenav{
  ul{
    margin: 4px 0 0 0;
  }
  li{
    margin: 4px 0;
  }
  a{
    padding: 10px 20px;
    background: #fff;
    font-size: 18px;
    text-decoration: none;
    display: block;
    position: relative;
    &::before, &::after{
      content: '';
      margin-top: -7px;
      border-left: 1px solid $baseFontColor;
      height: 8px;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: rotate(135deg);
    }
    &::after{
      margin-top: -1.5px;
      transform: rotate(-135deg);
    }
  }
}

// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  #l-sidenav{
    a{
      @include hoverEffectAlpha(linear, .1s, 0.6);
    }
  }
}


