
//slick-slider common
.slick-slider{
  .slick-slide{
    display: none;
    li{
      margin: 0;
      vertical-align: bottom;
      img{
        vertical-align: bottom;
      }
    }
    &:first-child{
      display: block;
    }
  }
  &.slick-initialized{
    .slick-slide{
      display: block;
    }
  }
}



//Slider used on the top page
.c-slide01{
  margin: 0;
  padding: 40px 0;
  background: $baseColor;
  .slick-slide{
    margin: 0 10px;
  }
  .slick-prev, .slick-next{
    margin-top: -25px;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    background: rgba($color: #ffffff, $alpha: 1.0);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .9;
    z-index: 9999;
    transition: opacity .3s ease, margin-left .3s ease;
    &:hover{
      opacity: .7;
    }
    &::before{
      content: '';
      border: 4px solid #000;
      border-right: transparent;
      border-bottom: transparent;
      width: 20px;
      height: 20px;
      display: block;
    }
  }
  .slick-prev{
    margin-left: -560px;
    left: 50%;
    transform: rotate(-45deg);
    &::before{
      margin: 4px 0 0 4px;
    }
  }
  .slick-next{
    margin-right: -560px;
    right: 50%;
    transform: rotate(135deg);
    &::before{
      margin: 0 -1px -3px 0;
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){

//Slider used on the top page
.c-slide01{
  .slick-prev, .slick-next{
    margin-top: -20px;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    transition: none;
    &:hover{
      opacity: .9;
    }
    &::before{
      content: '';
      border: 2px solid #000;
      border-right: transparent;
      border-bottom: transparent;
      width: 15px;
      height: 15px;
      display: block;
    }
  }
  .slick-prev{
    margin-left: 0;
    left: 10px;
    &::before{
      margin: 4px 0 0 4px;
    }
  }
  .slick-next{
    margin-right: 0;
    right: 10px;
    &::before{
      margin: 0 -1px -3px 0;
    }
  }
}



}