
// 会員情報などで使用
.c-table01{
  border-bottom: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
  font-family: 'noto_regular';
  text-align: left;
  tr{
    th{
      border-top: 1px solid #ccc;
      padding: 10px 10px 5px 10px;
      word-break: break-word;
    }
    td{
      padding: 5px 10px 10px 10px;
      word-break: break-word;
    }
  }
}

.c-table01--edit{
  td{
    width: calc(100% - 100px);
  }
  td:nth-child(2){
    width: 100px;
  }
}

.c-table02{
  border-collapse: collapse;
  width: 100%;
  th, td{
    border: 1px solid #ccc;
    padding: 5px;
    word-break: break-word;
  }
  tr:nth-child(2n-1){
    th, td{
      background: #f6f6f6;
    }
  }
}

.c-table02--payment{
  &--date{
    text-align: center;
  }
  &--price{
    text-align: right;
  }
  &--publish{
    text-align: center;
  }
}

.c-table03{
  border-collapse: collapse;
  border-bottom: 1px solid #ccc;
  width: 100%;
  th, td{
    border-top: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    word-break: break-word;
  }
  th{
    background: #f6f6f6;
  }
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  .c-table02{
    th, td{
      font-size: 13px;
    }
  }
}