//aside内にあるモジュールを定義
.c-aside__ranking{
  li{
    margin: 0 0 25px;
    a{
      color: #333;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      img{
        height: 100%;
        display: inline-block;
        flex-basis: 160px;
        max-width: 160px;
      }
    }
    &:nth-child(n+10){
      .c-aside__ranking__title{
        &::before{
          content: counter(number01);
        }
      }
    }
  }
}
.c-aside__ranking__text{
  flex-basis: 190px;
  max-width: 190px;
}
.c-aside__ranking__title{
  margin: 0 0 10px 10px;
  padding-left: 1em;
  font-family: 'noto_bold';
  font-size: 14px;
  text-indent: -.9em;
  display: block;
  &::before{
    content: "0" counter(number01);
    counter-increment: number01 1;
    margin: 0 .5em 0 0;
    color: #cda815;
    display: inline-block;
  }
}
.c-aside__ranking__author{
  color: $baseColor;
  font-size: 12px;
  display: block;
}

// window size less than 768px
// $rwdBreakPoint03 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  //aside内にあるモジュールを定義
  .c-aside__ranking{
    li{
      a{
        img{
          flex-basis: 40%;
          max-width: 40%;
        }
      }
    }
  }
  .c-aside__ranking__text{
    flex-basis: calc(60% - 10px);
    max-width: calc(60% - 10px);
  }
}


// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  //aside内にあるモジュールを定義
  .c-aside__ranking{
    li{
      a{
        img{
          flex-basis: 140px;
          max-width: 140px;
        }
      }
    }
  }
  .c-aside__ranking__text{
    flex-basis: calc(100% - 150px);
    max-width: calc(100% - 150px);
  }
}

