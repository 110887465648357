#l-main{
  background: #f6f6f6;
  z-index: 2;
  position: relative;
}
.l-breadcrumbs{
  margin: auto;
  width: $minContentWidth;
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &::before{
    content: '';
    background: $baseColor;
    @include createContent(0,0,0,0);
  }
  li{
    margin: 0 1em 0 0;
    font-size: 11px;
    a{
      color: #fff;
      text-decoration: none;
      &::after{
        content: '＞';
        margin: 0 0 0 1em;
      }
    }
    span{
      color: #fff;
    }
  }
}
.l-main__inner{
  padding: 60px 20px;
}

#l-pagetop{
  margin: 0 0 0 -35px;
  position: fixed;
  bottom: -35px;
  left: 50%;
  z-index: 9999;
  a{
    border-radius: 35px;
    width: 70px;
    height: 70px;
    padding: 10px;
    background: #586480;
    color: #fff;
    text-indent: -99999em;
    display: inline-block;
    position: relative;
    &::before, &::after{
      content: '';
      margin-left: -2px;
      border-top: 1px solid #fff;
      width: 13px;
      height: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 20px;
      left: 50%;
    }
    &::after{
      margin: 0 -2px 0 0;
      content: '';
      transform: rotate(-45deg);
      left: auto;
      right: 50%;
    }
  }
}


// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  .l-main__inner{
    margin: auto;
    width: $minContentWidth;
    &.__twocolumn{
      display: flex;
      justify-content: space-between;
      position: relative;
      #l-article{
        max-width: 820px;
        flex-basis: 820px;
      }
      #l-aside{
        max-width: 360px;
        flex-basis: 360px;
      }
    }
    &.__onecolumn{
      #l-article{
        margin: auto;
        width: 820px;
      }
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .l-breadcrumbs{
    width: 100%;
    li{
      font-size: 10px;
    }
  }
  .l-main__inner{
    padding: 30px 20px;
  }
}


