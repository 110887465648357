// Normal button
.c-button01, .c-button02{
  text-align: center;
  a, span{
    min-width: 100px;
    padding: 24px 20px;
    color: #fff;
    font-weight: bold;
    text-decoration: none!important;
    display: block;
  }
  a{
    span{
      min-width: auto;
      padding: 0;
    }
  }
  &.c-button01--full, .c-button02--full{
    width: 100%;
    a, span{
      width: 100%;
    }
  }
  .c-card01 + &{
    margin-top: 40px;
  }
}
// Base color - Navy
.c-button01{
  a, span{
    background: $baseColor;
  }
}

// Accent color - gold
.c-button02{
  a, span{
    background: $accentColor;
  }
}

// Amazon
.c-button03{
  text-align: center;
  a{
    border: 2px solid #f8991d;
    width: 400px;
    padding: 9px 18px;
    background: #fff;
    color: #f8991d;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
    position: relative;
    &::before{
      content: '';
      margin: -7px 0 0 0;
      width: 14px;
      height: 14px;
      background: url(/images/common/ico-blank.svg) no-repeat 0 0;
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
    }
    &::after{
      white-space: pre;
      content: url(/images/common/logo-amazon.png);
      margin: 5px 0 0 0;
      display: block;
    }
  }
}

// Utility - gray
.c-button04{
  margin: 0;
  min-width: 70px;
  text-align: center;
  a, span{
    border-radius: 5px;
    border: 1px solid $baseFontColor;
    padding: 5px 5px 8px 5px;
    background: rgb(240,240,240);
    background: linear-gradient(180deg, rgba(240,240,240,1) 0%, rgba(246,246,246,1) 59%, rgba(231,231,231,1) 100%);
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
  }
}

// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  .c-button01, .c-button02{
    a, span{
      @include hoverEffectAlpha(ease, .2s, .7);
    }
  }

  // Amazon
  .c-button03{
    a, span{
      @include hoverEffectAlpha(linear, .1s, 0.6);
    }
  }

    // Utility - gray
    .c-button04{
      a, span{
        @include hoverEffectAlpha(linear, .1s, 0.6);
      }
    }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  // Amazon
  .c-button03{
    a{
      width: 100%;
      padding: 9px 18px;
      &::after{
        margin: 2px 0 0 0;
        display: block;
        transform: scale(.8);
      }
    }
  }
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  .c-button01, .c-button02{
    a, span{
      font-size: 14px;
    }
  }
}