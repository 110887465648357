@charset "utf-8";
//====================================================================================================
//////////////////////////////////////////////////////////////////////////////////////////////////////
//
//    version: 1.0
//    created: 2021/03/22
//    update : -
//
//////////////////////////////////////////////////////////////////////////////////////////////////////
//====================================================================================================

//basic
$path: '../../';

// base width
$contentWidth: 1280px;
$contentWidthInner: 1240px;
$minContentWidth: 1280px;
$rwdBreakPoint01: 767.9px;
$rwdBreakPoint02: 768px;
$rwdBreakPoint03: 499.9px;
$rwdBreakPoint04: 500px;
$rwdBreakPoint05: 374.9px;

// base color
$baseFontColor: #333; //base font color
$baseLinkColor: #333; //link color
$baseColor: #001f53; //base color
$accentColor: #cda815; //key color

// base font
$baseFontFamily: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', "游ゴシック", "Yu Gothic", sans-serif;
$baseFontSize01: 15px;
