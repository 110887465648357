#l-footer{
  min-width: 100%;
  padding: 80px 0;
  background: $baseColor;
}

.l-footer__content__item{
  &:last-child{
    color: #fff;
    font-size: 10px;
    text-align: center;
  }
}


// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  .l-footer__content{
    margin: auto;
    width: $minContentWidth;
    padding: 0 20px;
    display: flex;
  }
  .l-footer__content__item{
    margin: 0 60px 0 0;
    flex-basis: 400px;
    max-width: 400px;
    &:last-child{
      flex-basis: 295px;
      max-width: 295px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .l-footer__heading{
    margin: 0 0 30px;
    border-bottom: 1px solid #586480;
    padding: 0 0 15px;
    color: #586480;
    font-size: 13px;
  }
  .l-footer__list{
    font-size: 13px;
    display: flex;
    ul{
      flex-basis: 50%;
      max-width: 50%;
    }
    li{
      a{
        color: #fff;
        font-weight: normal;
        @include hovertext(underline);
      }
    }
  }
  .l-footer__author p{
    flex-basis: 100%;
    max-width: 100%;
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  #l-footer{
    padding: 0;
  }
  .l-footer__logo{
    img{
      width: 120px;
    }
  }
  .l-footer__content__item{
    margin-top: -1px;
    border-top: 1px solid #2c426a;
    background: #fff;
    &:last-child{
      padding: 50px 20px;
      background: $baseColor;
    }
    &.is-active{
      .l-footer__heading{
        &::after{
          transform: rotate(0deg);
        }
      }
    }
  }
  .l-footer__heading{
    border-bottom: 1px solid #2c426a;
    padding: 20px;
    background: $baseColor;
    color: #fff;
    font-size: 14px;
    position: relative;
    &::before, &::after{
      content: '';
      width: 10px;
      height: 2px;
      background: #fff;
      display: block;
      position: absolute;
      right: 20px;
      top: 50%;
    }
    &::after{
      transform: rotate(90deg);
      @include setTransition(transform, .2s, ease-in);
    }
  }
  .l-footer__list{
    padding: 0 20px;
    font-size: 13px;
    li{
      margin: 0;
      a{
        border-bottom: 1px solid #e5e7ec;
        padding: 20px 0;
        text-decoration: none;
        display: block;
      }
    }
  }
}