//動画視聴ページ内のレイアウト
.c-detail{
  .c-detail__movie{
    margin: 0 0 30px;
  }
  .c-detail__info{
    margin: 0 0 40px;
    display: flex;
    li{
      border-right: 1px solid #ccc;
      padding: 10px 5px;
      font-size: 13px;
      text-align: center;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
      &:last-child{
        border: none;
      }
      .c-detail__heading{
        color: #9c9c9c;
        display: block;
      }
    }
  }
  .c-button03{
    margin: 0 0 50px;
  }
}

//form ボタンの下にある補助的なリンクなどに使用
//ログイン画面のパスワードリマインダなど
.c-auxiliary{
  margin: 30px 0 0 0;
  font-size: 14px;
  text-align: center;
  li{
    margin: 0;
  }
  a{
    color: #888;
    @include hovertext(underline);
  }
}

//about/indexで使用
.c-layout01{
  width: 100%;
  min-height: 630px;
  display: flex;
  position: relative;
  *{
    position: relative;
    z-index: 10;
  }
  &__inner{
    padding: 80px 0 0 ;
    position: static;
  }
  &__lead{
    margin: 0 0 20px;
    color: $baseColor;
    font-family: 'noto_bold';
    font-size: 30px;
    position: relative;
    span{
      margin: 0 0 20px;
      display: block;
    }
  }
  &__img{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  &--reverse{
    justify-content: flex-end;
    .c-layout01__img{
      right: auto;
      left: 0;
    }
  }
}


// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  //主にフォームの幅400pxのブロックに使用
  .c-block01{
    margin: auto;
    width: 400px;
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  //動画視聴ページ内のレイアウト
  .c-detail{
    .c-detail__movie{
      margin: 0 0 15px;
    }
    .c-detail__info{
      margin: 0 0 15px;
      display: block;
      li{
        margin: 0;
        border-right: none;
        width: 100%;
        max-width: none;
        padding: 5px 0;
        font-size: 12px;
        text-align: left;
        display: flex;
        .c-detail__heading{
          flex-basis: 6.5em;
          max-width: 6.5em;
        }
        .c-detail__data{
          flex-basis: calc(100% - 6.5em);
          max-width: calc(100% - 6.5em);
        }
      }
    }
    .c-button03{
      margin: 0 0 50px;
    }
  }

  //about/indexで使用
  .c-layout01{
    width: 100%;
    min-height: auto;
    display: block;
    &__inner{
      padding: 0;
    }
    &__lead{
      margin: 0 0 20px;
      font-size: 24px;
      text-align: center;
      span{
        margin: 0 0 0;
        position: absolute;
        left: 50%;
        display: inline-block;
      }
    }
    &__img{
      margin: 0 -20px 1em;
      position: static;
      right: auto;
      top: auto;
      img{
        width: 100%;
      }
    }
  }
}

// window size less than 500px
// $rwdBreakPoint03 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  //about/indexで使用
  .c-layout01{
    &__lead{
      margin-top: 30px;
      font-size: 20px;
    }
  }
}