#l-header{
  background-color: #fff;
  z-index: 3;
  position: relative;
}
.l-header__content{
  margin: auto;
  width: $minContentWidth;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.l-header__title{
  img{
    vertical-align: top;
  }
}
.l-header__menu{
  display: flex;
  align-items: center;
}
.l-header__menu__content{
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  overflow: auto;
}
.l-header__gnav--lv01{
  margin: 0 140px 0 30px;
  display: flex;
  li{
    margin: 0;
    padding: 40px 30px;
    font-weight: bold;
    cursor: pointer;
    span{
      padding: 0 20px 0 0;
      display: inline-block;
      position: relative;
      &::before, &::after{
        content: '';
        margin-top: 0px;
        border-top: 1px solid #666;
        width: 8px;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 6px;
        transform: rotate(45deg);
        @include setTransition(margin, .2s, ease-in);
      }
      &::after{
        right: 0;
        transform: rotate(-45deg);
      }
    }
    a{
      @include hovertext(underline);
    }
    &:hover span{
      &::before, &::after{
        margin-top: 2px;
      }
    }
  }
}
.l-header__gnav__megamenu{
  width: $minContentWidth;
  background: $baseColor;
  cursor: auto;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  li:hover &{
    display: block;
  }
}
.l-header__gnav--lv02{
  padding: 50px 60px 25px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &::before{
    @include createContent(0, 0, 0, 0);
    background: $baseColor;
  }
  li{
    margin: 0 0 25px;
    padding: 0;
    font-weight: normal;
    cursor: auto;
    flex-basis: 25%;
    a{
      padding: 0 0 0 20px;
      color: #fff;
      text-decoration: none;
      position: relative;
      &::before, &::after{
        content: '';
        margin-top: -7px;
        border-left: 1px solid #fff;
        height: 8px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0px;
        transform: rotate(135deg);
      }
      &::after{
        margin-top: -1.5px;
        transform: rotate(-135deg);
      }
    }
    span{
      padding: 0;
      display: inline-block;
      position: relative;
    }
    a:hover span{
      &::before{
        transform: translateX(100%);
      }
    }
  }
}

#l-header{
  .l-header__search{
    margin: 0 20px 0 0;
    position: relative;
    .c-form__text{
      width: 270px;
      padding: 12px 20px;
      font-size: 16px;
    }
    .l-header__search__button{
      margin: -9px 0 0 0;
      width: 18px;
      height: 18px;
      background: url(/images/common/ico-search-gray.svg) no-repeat 0 0;
      display: block;
      position: absolute;
      top: 50%;
      right: 10px;
      &:hover{
        background: url(/images/common/ico-search-blue.svg) no-repeat 0 0;
      }
    }
  }
}

.l-header__button{
  display: flex;
  li{
    margin: 0 0 0 10px;
    a{
      padding: 15px 20px;
    }
  }
}

.l-header__account{
  margin: 0 20px 0 60px;
  font-size: 12px;
  span{
    color: $baseColor;
    font-family: "noto_bold";
    font-size: 14px;
    &::before{
      content: '';
      margin: -3px 7px 0 0;
      width: 18px;
      height: 18px;
      background: url(/images/common/ico-member.svg) no-repeat 0 0;
      vertical-align: middle;
      display: inline-block;
    }
  }
}

// window size more than 768px
// $rwdBreakPoint01 = 768
@media screen and (min-width:$rwdBreakPoint02){
  .l-header__menu__button{
    display: none;
  }
  .l-header__gnav--lv02{
    a{
      span{
        &::before{
          content: '';
          border-top: 3px solid $baseColor;
          width: 100%;
          height: 1px;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          top: auto;
          bottom: -10px;
          transform: rotate(0deg);
          transform: translateX(0%);
          @include setTransition(transform, .2s, ease-in);
          z-index: 10;
        }
        &::after{
          content: '';
          border-top: 1px solid #fff;
          width: 100%;
          height: 1px;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          top: auto;
          bottom: -9px;
          transform: rotate(0deg);
        }
      }
      &:hover span{
        &::before{
          transform: translateX(100%);
        }
      }
    }
  }
}

// window size less than 768px
// $rwdBreakPoint01 = 767.9
@media screen and (max-width:$rwdBreakPoint01){
  .l-header__content{
    width: 100%;
    padding: 10px 20px;
  }
  .l-header__title{
    padding: 7px 0 0;
    flex-basis: 120px;
    max-width: 120px;
    a{
      display: inline-block;
    }
    img{
      vertical-align: unset;
    }
  }
  .l-header__menu{
    flex-direction: row-reverse;
  }
  .l-header__menu__button{
    width: 20px;
    height: 20px;
    position: relative;
    &::after{
      content: '';
      width: 20px;
      height: 3px;
      background: $baseColor;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    span{
      &::before, &::after{
        content: '';
        width: 20px;
        height: 3px;
        background: $baseColor;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after{
        margin-top: -1.5px;
        top: 50%;
      }
    }
    .is-active & {
      &::after{
        content: none;
      }
      span{
        &::before{
          margin: 0;
          transform: rotate(45deg);
          top: 50%;
        }
        &::after{
          margin: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .l-header__menu__content{
    .is-active & {
      background: $baseColor;
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
  .l-header__button{
    margin: 0 30px;
  }
  #l-header{
    .l-header__search{
      width: 100%;
      padding: 35px 20px;
      position: relative;
      .c-form__text{
        width: 100%;
      }
      .l-header__search__button{
        right: 30px;
      }
    }
  }

  .l-header__gnav{
    padding-bottom: 40px;
  }
  .l-header__gnav--lv01{
    margin: 0;
    border-bottom: 1px solid #fff;
    display: block;
    li{
      border-top: 1px solid #fff;
      padding: 0;
      color: #fff;
      font-size: 17px;
      font-weight: normal;
      display: block;
      a{
        padding: 20px;
        color: #fff;
        display: block;
        position: relative;
        &::before, &::after{
          content: '';
          margin-top: -7px;
          border-left: 1px solid #fff;
          height: 8px;
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: rotate(135deg);
        }
        &::after{
          margin-top: -1.5px;
          transform: rotate(-135deg);
        }
      }
      span{
        padding: 15px 20px;
        background: #586480;
        display: block;
      }
    }
  }
  .l-header__gnav__megamenu{
    border-bottom: 1px solid #2c426a;
    width: 100%;
    display: block;
    position: static;
  }
  .l-header__gnav--lv02{
    padding: 0;
    &::before{
      content: none;
    }
    li{
      margin: 0;
      border-top: none;
      border-bottom: 1px solid #2c426a;
      border-right: 1px solid #2c426a;
      padding: 0;
      font-size: 14px;
      flex-basis: 50%;
      max-width: 50%;
      a{
        padding: 20px 40px 20px 20px;
        display: block;
        &::before, &::after{
          left: auto;
          right: 20px;
        }
      }
      span{
        padding: 0;
        background: none;
        display: inline-block;
        &::before, &::after{
          content: none;
        }
      }
    }
  }
  .l-header__account{
    margin: 0 40px 0 0;
  }
}

// window size less than 500px
// $rwdBreakPoint01 = 499.9
@media screen and (max-width:$rwdBreakPoint03){
  .l-header__title{
    flex-basis: 97px;
    max-width: 97px;
    font-size: 24px;
  }

  .l-header__menu__button{
    width: 16px;
    height: 16px;
    &::after{
      width: 16px;
      height: 2px;
    }
    span{
      &::before, &::after{
        content: '';
        width: 16px;
        height: 2px;
      }
      &::after{
        margin-top: -1px;
        top: 50%;
      }
    }
  }

  .l-header__button{
    margin: 0 15px;
    li{
      margin: 0 0 0 5px;
      a{
        min-width: auto;
        padding: 15px 10px;
        font-size: 12px;
      }
    }
  }
}